
export default {
  name: 'SghServiceCarousel',
  props: {
    titleModule: {
      type: String,
      default: '',
    },
  },

  computed: {
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    gridClass() {
      const length = this.slotCount
      if (length === 1) return 'sgh-service-carousel-grid_one'
      if (length === 2) return 'sgh-service-carousel-grid_two'
      if (length === 3) return 'sgh-service-carousel-grid_three'
      return 'sgh-service-carousel-grid_three'
    },
    slotCount() {
      return (this.$slots.default || []).filter(vnode => {
        return vnode.tag || (vnode.text && vnode.text.trim())
      }).length
    },
  },
}
