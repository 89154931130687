
export default {
  name: 'SghCategoryModule',
  props: {
    textColor: {
      type: String,
      default: 'cms-custom-text-color-black',
    },
    backgroundColor: {
      type: String,
      default: 'cms-custom-color-white',
    },
    textAlign: {
      type: String,
      default: 'center',
    },
  },
  data() {
    return {
      numberElements: 0,
    }
  },
  computed: {
    hasMoreElements() {
      return this.numberElements > 4
    },
  },
  mounted() {
    const elements = this.$slots.items
      ? this.$slots?.items.filter(vnode => vnode.componentOptions?.tag == 'sgh-category-module-item')
      : 0
    this.numberElements = elements.length
  },
}
