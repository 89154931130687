
export default {
  name: 'SghServiceModule',
  props: {
    fullBlock: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String,
      default: 'center',
      validator: value => ['left', 'center'].includes(value),
    },
    bgColor: {
      type: String,
      default: 'cms-custom-color-white-smoke',
    },
    textColor: {
      type: String,
      default: 'cms-custom-text-color-black',
    },
    numberElements: {
      type: Number,
      default: 2,
    },
    imageDesktop: {
      type: String,
      default: '',
    },
    imageMobile: {
      type: String,
      default: '',
    },
    imageAlt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSwiperFirst: true,
      resizeObserver: null,
    }
  },
  computed: {
    alignmentClass() {
      return {
        'align-left': this.alignment === 'left',
        'align-center': this.alignment === 'center',
      }
    },
    backgroundStyle() {
      let backgroundImage = ''

      if (this.isMobile) {
        backgroundImage = this.imageMobile ? `url(${this.imageMobile})` : ''
      } else {
        backgroundImage = this.imageDesktop ? `url(${this.imageDesktop})` : ''
      }

      return backgroundImage
        ? `background-image: ${backgroundImage}; background-size: cover; background-position: center;`
        : ''
    },
    bgColorClass() {
      return this.bgColor ? this.bgColor : ''
    },
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    hasMoreElements() {
      return this.numberElements > 4
    },
    numberBanner() {
      return this.hasMoreElements ? '' : { '--banner-number': this.numberElements }
    },
  },
  mounted() {
    this.initResizeObserver()
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(() => {
        this.updateSwiper()
      })
      if (this.$refs.sghServiceModule) {
        this.resizeObserver.observe(this.$refs.sghServiceModule)
      }
    },
    updateSwiper() {
      if (this.$refs.swiperCarousel?.swiper) {
        this.$refs.swiperCarousel.swiper.update()
      }
    },
    onSlideChange() {
      const swiper = this.$refs?.swiperCarousel?.swiper
      this.isSwiperFirst = swiper.isBeginning
    },
  },
}
