
  /*
    This is a generic CMS component to manage video in CMS modules
    that can be used standalone or nested in other CMS components

    Attributes to communicate to CMS
      data-mobile-poster
      data-desktop-poster
      data-mobile-src
      data-desktop-src
      autoplay
      loop
      muted
      poster
      controls-right
      playsinline

    About missing type attribute in source tag
      https://developer.mozilla.org/en-US/docs/Web/HTML/Element/source#usage_notes

      If the type attribute isn't specified, 
      the browser retrieves the media's type from the server and determines if 
      it can be displayed. 
      If the media can't be rendered, the browser checks 
      the next <source> in the list.
  */
  export default {
    props: {
      autoplay: {
        type: Boolean,
        default: false,
      },
      contentId: {
        type: String,
        default: '',
      },
      controlsRight: {
        type: Boolean,
        default: false,
      },
      desktopPoster: {
        type: String,
        default: '',
      },
      desktopSrc: {
        type: String,
        default: '',
      },
      hideSoundControl: {
        type: Boolean,
        default: false,
      },
      hideFullScreenBtn: {
        type: Boolean,
        default: true,
      },
      loop: {
        type: Boolean,
        default: false,
      },
      mobilePoster: {
        type: String,
        default: '',
      },
      mobileSrc: {
        type: String,
        default: '',
      },
      muted: {
        type: Boolean,
        default: false,
      },
      playsinline: {
        type: Boolean,
        default: false,
      },
      playTitle: {
        type: String,
        default: 'play',
      },
    },
    data() {
      return {
        pauseClass: 'ico-pause-custom',
        playClass: 'ico-play-custom',
        isMuted: true,
        isPlaying: false,
      }
    },
    computed: {
      isMobile() {
        return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
      },
      setPoster() {
        return this.isMobile ? this.mobilePoster : this.desktopPoster
      },
      setSrc() {
        return this.isMobile ? this.mobileSrc : this.desktopSrc
      },
      currentVideoState() {
        return this.isPlaying ? this.pauseClass : this.playClass
      },
      setAutoplay() {
        return this.autoplay
      },
      setLoop() {
        return this.loop
      },  
      setMuted() {
        return this.muted
      },
      setPlaysinline() {
        return this.playsinline
      },
    },
    mounted() {
      //set loop value
      this.$refs.videoTag.loop = this.loop ? true : false

      //add handlers for relevant buttons displaying if user change something in a fullscreen mode
      if(!this.hideFullScreenBtn) {
        this.addListenersForFullScreenMode()
      }

      //autoplay
      if(this.autoplay) {
        this.playHandler()
      }
    },
    methods: {
      playHandler() {
        this.isPlaying ? this.$refs.videoTag.pause() : this.$refs.videoTag.play()
        this.isPlaying = !this.isPlaying
      },
      soundHandler() {
        this.isMuted = !this.isMuted
        this.$refs.videoTag.muted = this.isMuted
      },
      openFullscreen() {
        this.$refs.videoTag.webkitEnterFullscreen ? this.$refs.videoTag.webkitEnterFullscreen() : this.$refs.videoTag.requestFullscreen()
      },
      addListenersForFullScreenMode() {
        //if sound control is present
        if(!this.hideSoundControl) {
          this.$refs.videoTag.addEventListener('volumechange', e => this.isMuted = e.target.muted)
        }

        ['pause', 'play'].forEach((ev) => {
          this.$refs.videoTag.addEventListener(ev, e => this.isPlaying = e.type === 'play')
        })
      },
    },
  }
