import Vue from 'vue'

/* All components to be declared */
/* Declare in this way components that will be put in chunks and loaded not immediately */
Vue.component('SghCmsFaces', () =>
  import(
    /* webpackChunkName: "faces" */
    './components/faces/cms-faces.vue'
  )
)

Vue.component('SghGetSupportContactUs', () =>
  import(
    /* webpackChunkName: "get-support" */
    './components/get-support/get-support-contact-us.vue'
  )
)

Vue.component('SghGetSupportGiftcard', () =>
  import(
    /* webpackChunkName: "get-support" */
    './components/get-support/get-support-giftcard.vue'
  )
)

Vue.component('SghModal', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/modal/sgh-modal.vue'
  )
)

Vue.component('SghCmsLandscapeBannerModule', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/cms-modules/landscape-banner-module/landscape-banner.vue'
  )
)

Vue.component('SghShortcode', () =>
  import(
    /* webpackChunkName: "get-support" */
    './components/shortcode/sgh-shortcode.vue'
  )
)

Vue.component('SghSizeAdvisorEntryPoint', () =>
  import(
    /* webpackChunkName: "size-advisor" */
    './components/size-advisor/sgh-size-advisor-entry-point.vue'
  )
)

Vue.component('SghSizeAdvisor', () =>
  import(
    /* webpackChunkName: "size-advisor" */
    './components/size-advisor/size-advisor.vue'
  )
)

Vue.component('SghSizeGuide', () =>
  import(
    /* webpackChunkName: "size-guide" */
    './components/size-guide/size-guide.vue'
  )
)

Vue.component('SghSizeguideBadges', () =>
  import(
    /* webpackChunkName: "size-guide" */
    './components/size-guide/sizeguide-badges.vue'
  )
)

Vue.component('SghSizeguideContainer', () =>
  import(
    /* webpackChunkName: "size-guide" */
    './components/size-guide/sizeguide-container.vue'
  )
)

Vue.component('SghSizeSelectorList', () =>
  import(
    /* webpackChunkName: "size-list" */
    './components/size-selector/size-selector-list.vue'
  )
)

Vue.component('CmsC4Slider', () =>
  import(
    /* webpackChunkName: "cms-slider" */
    './components/slider/cms-c4-slider.vue'
  )
)

Vue.component('CmsVideoSlider', () =>
  import(
    /* webpackChunkName: "cms-slider" */
    './components/slider/cms-video-slider.vue'
  )
)

Vue.component('SghThankPromo', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/thank-page/thank-promo.vue'
  )
)

Vue.component('SghCategoryModuleItem', () =>
  import(
    /* webpackChunkName: "banner" */
    './components/cms-modules/category-module/category-module-item.vue'
  )
)

Vue.component('SghCmsGridPlpTiles', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/tile/cms-grid-plp-tiles.vue'
  )
)

Vue.component('SghLoyaltyBanner', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-banner.vue'
  )
)

Vue.component('SghLoyaltyModal', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-modal.vue'
  )
)
Vue.component('SghLoyaltyTile', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-tile.vue'
  )
)

Vue.component('SghLoyaltyConfirmationBanner', () =>
  import(
    /* webpackChunkName: "loyalty" */
    './components/loyalty/loyalty-confirmation-banner.vue'
  )
)

Vue.component('SghSlLocationSearch', () =>
  import(
    /* webpackChunkName: "store-locator" */
    './components/location-search/sl-location-search.vue'
  )
)

Vue.component('SghTooltipBox', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/tooltip/tooltip-box.vue'
  )
)

Vue.component('SghSelect', () =>
  import(
    /* webpackChunkName: "sgh-select" */
    './components/ui-components/select.vue'
  )
)

Vue.component('sgh-notifymail', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/my-account/components/notifymail/sgh-notifymail.vue'
  )
)

Vue.component('sgh-faqs-implementation', () =>
  import(
    /* webpackChunkName: "wordlift-faq" */
    './components/ui-components/faqs-implementation.vue')
)

/* Declare in this way component that needs to appear immediately (will be put in main.js) */
import heroBanner from './components/hero-banner/hero-banner.vue'
Vue.component('SghHeroBanner', heroBanner)

import sghSwiperTiles from './components/tile/cms-swiper-tiles.vue'
Vue.component('SghSwiperTiles', sghSwiperTiles)

import sghCmsProductTile from './components/tile/cms-tile.vue'
Vue.component('SghCmsProductTile', sghCmsProductTile)

import cmsWallSlider from './components/slider/cms-wall-slider.vue'
Vue.component('CmsWallSlider', cmsWallSlider)

import sghBrandWall from './components/ui-components/sgh-brand-wall.vue'
Vue.component('SghBrandWall', sghBrandWall)

import sghServiceCarousel from './components/service-module/sgh-service-carousel.vue'
Vue.component('SghServiceCarousel', sghServiceCarousel)

import sghServiceCarouselItem from './components/service-module/sgh-service-carousel-item.vue'
Vue.component('SghServiceCarouselItem', sghServiceCarouselItem)

import sghServiceModule from './components/service-module/sgh-service-module.vue'
Vue.component('SghServiceModule', sghServiceModule)

import sghServiceItem from './components/service-module/sgh-service-item.vue'
Vue.component('SghServiceItem', sghServiceItem)

import sghCategoryModule from './components/cms-modules/category-module/category-module.vue'
Vue.component('SghCategoryModule', sghCategoryModule)

import sghCategoryModuleItem from './components/cms-modules/category-module/category-module-item.vue'
Vue.component('SghCategoryModuleItem', sghCategoryModuleItem)

import sghCmsVideo from './components/cms-modules/shared-components/cms-video.vue'
Vue.component('sghCmsVideo', sghCmsVideo)

import sunLoveProtection from './components/sun-love-protection/sun-love-protection.vue'
Vue.component('SghSunLoveProtection', sunLoveProtection)

import sghPaymentInfo from './components/payment-info/sgh-payment-info.vue'
Vue.component('SghPaymentInfo', sghPaymentInfo)

import sghFullWidth from './components/full-width-banner/full-width.vue'
Vue.component('SghFullWidth', sghFullWidth)

Vue.component('SghNotifymail', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/my-account/components/notifymail/sgh-notifymail.vue'
  )
)

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Rtrbanner', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/rtr-banner/rtr-banner.vue'
  )
)
